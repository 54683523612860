import React from "react";
import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import wrapURL from "../WrapUrl";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    textAlign: "left",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  link: {
    color: "#000",
    textDecoration: "none",
  },
  toolbar: {
    backgroundColor: "#21211B",
  },
};

function TopBar(props) {
  const { classes } = props;

  const [anchorNav, setAnchorNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorNav(null);
  };

  const pages = [
    ["Home", "/"],
    ["About", "/about"],
    ["CNC Services", "/services"],
    ["Gallery", "/gallery"],
    ["Projects", "/projects"],
    ["Products", "/products"],
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Box margin={"auto"}>
            <Typography variant="h6" color="inherit" className={classes.grow} marginTop="10px">
              <Link to={wrapURL("/")} className={classes.link}>
                <img src={wrapURL("/logo.png")} padding="10px" height="160" />
              </Link>
            </Typography>
          </Box>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
            {pages.map(([title, link]) => (
              <Button component={Link} to={wrapURL(link)} color="inherit">
                {title}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(([title, link]) => (
                <MenuItem
                  key={title}
                  component={Link}
                  to={wrapURL(link)}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">{title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(TopBar);
