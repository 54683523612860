import React from "react";
import { Alert } from "@mui/material";
import Header from "../components/Header";

const ServicesPage = () => (
  <>
    <Header>
      <h1>CNC Services</h1>
    </Header>
    <Alert variant="filled" severity="info">Coming Soon!</Alert>
  </>
);

export default ServicesPage;
