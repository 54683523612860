import React from "react";
import wrapURL from "../WrapUrl";

const HomePage = () => (
  <>
    <div
      style={{
        width: "100%",
        padding: 0,
        margin: 0,
      }}
    >
      <h1>Hello and Welcome</h1>
    </div>
    <img
      src={wrapURL("/gallery/mrwalls_wynn_casino.jpg")}
      style={{ width: "100%", height: 700, padding: 0, margin: 0, objectFit: "cover" }}
    />
  </>
);

export default HomePage;
