import React from "react";
import { Alert, Button } from "@mui/material";
import Header from "../components/Header";
import DownloadIcon from "@mui/icons-material/Download";

const ProjectsPage = () => (
  <>
    <Header>
      <h1>Products</h1>
    </Header>

    <Button variant="contained" startIcon={<DownloadIcon />}>
        Download Catalogue (Coming Soon)
    </Button>
  </>
);

export default ProjectsPage;