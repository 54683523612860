import React from "react";

const Header = (props) => (
  <div
    style={{
      width: "100%",
      padding: 0,
      margin: 0,
    }}
  >
    {props.children}
  </div>
);

export default Header;
