import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import { Button, Drawer, createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import HomePage from "./containers/HomePage";
import ServicesPage from "./containers/ServicesPage";
import ProjectsPage from "./containers/ProjectsPage";
import ProductsPage from "./containers/ProductsPage";
import GalleryPage from "./containers/GalleryPage";
import wrapURL from "./WrapUrl";
import AboutPage from "./containers/AboutPage";
import PrivacyPage from "./containers/PrivacyPage";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#8E8E8E",
    },
    secondary: {
      main: "#615B4B",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#8E8E8E",
    },
    secondary: {
      main: "#615B4B",
    },
  },
});

const styles = {
  content: {
    marginLeft: 20,
    marginRight: 20,
  },
};

const App = () => {
    const [consentDismissed, setConsentDismissed] = useState(false);
    return (
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <BrowserRouter>
          <div className="App">
            <TopBar />
            <div
              style={{ marginTop: 16, padding: 8 }}
            >
              <Switch>
                <Route path={wrapURL("/about")} component={AboutPage} />
                <Route
                  path={wrapURL("/services")}
                  component={ServicesPage}
                />
                <Route path={wrapURL("/gallery")} component={GalleryPage} />
                <Route
                  path={wrapURL("/projects")}
                  component={ProjectsPage}
                />
                <Route
                  path={wrapURL("/products")}
                  component={ProductsPage}
                />
                <Route path={wrapURL("/privacy")} component={PrivacyPage} />
                <Route path={wrapURL("/")} component={HomePage} />
              </Switch>
            </div>
          </div>
          <BottomBar />
          <Drawer
            anchor="bottom"
            open={!consentDismissed}
          >
            <div style={{padding: "16px"}}>
              <h4>Cookie Preferences</h4>
              <p>Jigbox Design use essential cookies, including third party cookies to ensure our site can function.</p>
              <p>In future, we may request to set non-essential cookies to personalise and improve your experience as well as deliver relevant advertising. You can refuse this.</p>
              <p>Read more in our Privacy Policy.</p>

              <Button onClick={() => setConsentDismissed(true)}>Reject Non-essential</Button> <Button variant="contained" onClick={() => setConsentDismissed(true)}>Accept All</Button>
            </div>
          </Drawer>
        </BrowserRouter>
      </ThemeProvider>
    );
  }

export default withStyles(styles)(App);
