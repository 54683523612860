import React from "react";
import { Alert } from "@mui/material";
import Header from "../components/Header";

const AboutPage = () => (
  <>
    <Header>
        <h1>About Jigbox Design</h1>
    </Header>

    <p>
      Welcome to Jigbox Design, the premier provider of CNC cutting and bespoke component manufacturing services for the joinery and shopfitting industry. At Jigbox Design, we are committed to delivering high-quality, precision-made components that meet our clients' unique requirements.
    </p>
    <p>
      Our state-of-the-art CNC cutting machines enable us to produce accurate and efficient components, while our experienced team of designers and engineers work closely with our clients, including interior designers and architects, to develop bespoke solutions that meet their specific needs. We understand that every project is unique, which is why we offer tailored design and manufacturing services to ensure that our clients receive the components they need to bring their ideas to life.
    </p>
    <p>
      In addition to our CNC cutting services, spray-painted finishes, and bespoke cabinetry and furniture manufacturing services, we also offer solid surface materials such as Corian and HIMACS. These materials are ideal for use in the pharmacy and dental industry, as they are Antimicrobial (Bacteria, Mold, and Mildew Resistant), which reduces germ spreading and ensures a hygienic environment.
    </p>
    <p>
      Our collaboration with interior designers and architects allows us to produce components and cabinetry that not only meet functional requirements but also align with the overall aesthetic and design of a space. Our experienced team of designers and engineers work closely with our clients to understand their design requirements and develop tailored solutions that meet their specific needs.
    </p>
    <p>
      At Jigbox Design, we pride ourselves on our commitment to customer satisfaction. We understand that our clients are looking for high-quality components, cabinetry, and solid surface materials that are delivered on time and within budget. That's why we work tirelessly to ensure that all our projects are completed to the highest standards, with the utmost attention to detail.
    </p>
    <p>
      Whether you are an interior designer or architect looking for precision-made components, bespoke cabinetry, or solid surface materials that reduce germ spreading and are Antimicrobial, or a joinery or shopfitting business seeking high-quality CNC cutting services, Jigbox Design is here to help. Contact us today to discuss your project requirements and let us help you bring your ideas to life!
    </p>
  </>
);

export default AboutPage;
