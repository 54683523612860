import React from "react";
import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Container, Grid, Typography, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import wrapURL from "../WrapUrl";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TikTokIcon from "../icons/TikTokIcon";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    textAlign: "left",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  link: {
    color: "#FFF",
    textDecoration: "none",
  },
  toolbar: {
    backgroundColor: "#21211B",
  },
};

function BottomBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            &copy; Jigbox Design LTD
          </Typography>
        </Toolbar>
        <Toolbar className={classes.toolbar}>
          <Grid container direction="column" spacing="2">
            <Grid item>
              <p>
                All information on this website is subject to change without
                prior notice!
              </p>
            </Grid>
            <Grid>&nbsp;</Grid>
            <Grid item direction="row" spacing="2">
              <Grid sx={3} item direction="column" spacing="2">
                <Grid>
                  <b>Contact</b>
                </Grid>
                <Grid>info@jigboxdesign.com</Grid>

                <Grid item>
                  <IconButton onClick={() => {window.open("https://instagram.com/jigbox_designltd", "_blank")}} color="inherit">
                    <InstagramIcon />
                  </IconButton>
                  <IconButton onClick={() => {window.open("https://www.facebook.com/jigboxdesign", "_blank")}} color="inherit">
                    <FacebookIcon />
                  </IconButton>
                  <IconButton onClick={() => {}} color="inherit">
                    <TwitterIcon />
                  </IconButton>
                  <IconButton onClick={() => {}} color="inherit">
                    <YouTubeIcon />
                  </IconButton>
                  <IconButton onClick={() => {window.open("https://www.tiktok.com/@jigbox_design", "_blank")}} color="inherit">
                    <div style={{width: 30}}>
                      <TikTokIcon/>
                    </div>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid sx={3} item direction="column" spacing="2">
                <Grid>&nbsp;</Grid>
                <Grid>
                  <b>Location</b>
                </Grid>
                <Grid>Jigbox Design Ltd</Grid>
                <Grid>Unit 1</Grid>
                <Grid>Station Road</Grid>
                <Grid>Old Kilpatrick</Grid>
                <Grid>Glasgow</Grid>
                <Grid>G60 5LP</Grid>
                <Grid>&nbsp;</Grid>
              </Grid>
              <Grid sx={3} item direction="column" spacing="2">
                <Grid>
                  <Link to={wrapURL("/privacy")} className={classes.link}>
                    <b>Privacy Policy</b>
                  </Link>
                </Grid>
                <Grid>&nbsp;</Grid>
                <Grid item>&copy; Jigbox Design LTD</Grid>
                <Grid>&nbsp;</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(BottomBar);
