import React, { useState } from "react";
import { Typography, Card, CardMedia, Grid, Backdrop, CardContent } from "@mui/material";
import wrapURL from "../WrapUrl";
import Header from "../components/Header";

const GalleryPage = () => {
  let images = [
    ["machine.png", "... Coming soon..."],
    ["mrwalls_wynn_casino.jpg", "... Coming soon..."],
  ];

  const [selected, setSelected] = useState(null);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!selected}
        onClick={() => setSelected(null)}
      >
        <img src={selected} />
      </Backdrop>
      <Header>
        <h1>Gallery</h1>
      </Header>
      <Grid container>
        <Grid item xs={12}>
          <Card style={{ padding: 8 }}>
            <Header>
              <h2>Images</h2>
            </Header>
            <Grid container>
              {images.map(([img, desc]) => (
                <Grid item xs={3} padding={"10px"}>
                  <Card key={img}>
                    <CardMedia
                      image={wrapURL(`/gallery/${img}`)}
                      style={{
                        height: 0,
                        paddingTop: '56.25%'
                      }}
                      onClick={() => setSelected(wrapURL(`/gallery/${img}`))}
                    />
                    <CardContent style={{textAlign: "right"}}>
                      {desc}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default GalleryPage;
